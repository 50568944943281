<template>
  <div>

    <div class="card-text">
      {{$t('Constructor.PropWidgets.AutoValidateFormWidget.Info')}}
    </div>
    <div
        v-for="input_id in getAllFormElemFromCurrentWin()"
        :key = input_id
    >
      <b-form-group
          inline
          :label-cols-sm="4"
          :label-cols-lg="4"
          :label="getElemName(input_id)"
          :title="$t('General.Base.Field')"
          v-b-tooltip.hover
      >
        <b-input-group>
          <b-form-select
              :title="$t('Constructor.PropWidgets.AutoValidateFormWidget.SelectTooltip')"
              class="col-12"
              :options="getAllElementsCurrentWinToOptions(true, {tag: ['div', 'span', 'p']})"
              v-model="actionObj.data[input_id].textErrorElemId"
              v-b-tooltip.hover
          ></b-form-select>
        </b-input-group>
      </b-form-group>

      <b-form-group
          inline
          :label-cols-sm="4"
          :label-cols-lg="4"
          :label="$t('General.Base.RequiredField')"
      >
        <b-form-input
            placeholder="No set"
            type="text"
            v-model="actionObj.data[input_id].textRequired"
            :name="'textRequired'+input_id"
        ></b-form-input>
      </b-form-group>
      <b-form-group
          inline
          :label-cols-sm="4"
          :label-cols-lg="4"
          :label="$t('Constructor.PropWidgets.AutoValidateFormWidget.LabelManyCharacters')"
      >
        <b-form-input
            placeholder="$t('General.Form.NoSet')"
            type="text"
            v-model="actionObj.data[input_id].textMaxlength"
            :name="'textRequired'+input_id"
        ></b-form-input>
      </b-form-group>
      <b-form-group
          inline
          :label-cols-sm="4"
          :label-cols-lg="4"
          :label="$t('Constructor.PropWidgets.AutoValidateFormWidget.LabelIncorrectData')"
      >
        <b-form-input
            placeholder="No set"
            type="text"
            v-model="actionObj.data[input_id].textNoValid"
            :name="'textRequired'+input_id"
        ></b-form-input>
      </b-form-group>

      <hr>

    </div>
  </div>
</template>

<script>
import TagDataGetElemsHelper from "@/helpers/constructor/TagDataGetElemsHelper";
import HistoryHelper from '@/helpers/constructor/HistoryHelper'

export default {
  name: "AutoValidateFormWidget",
  props: ["tagData", "settingConstructor", "basePropElems", "actionObj"],
  data: function() {
    return {
      dataPatternElem: {
          textErrorElemId: null,
          textRequired: this.$t('Constructor.PropWidgets.AutoValidateFormWidget.TextRequired'),
          textMaxlength: this.$t('Constructor.PropWidgets.AutoValidateFormWidget.TextMaxlength'),
          textNoValid: this.$t('Constructor.PropWidgets.AutoValidateFormWidget.TextNoValid')
      },
    }
  },
  created() {
    this.getAllFormElemFromCurrentWin().forEach((elem_id) => {
      if (this.actionObj.data[elem_id] === undefined) {
        this.HistorySet(this.actionObj.data, elem_id, this.dataPatternElem)
        // this.$set(this.actionObj.data, elem_id, this.dataPatternElem)
      }
    })

    // if (this.actionObj.data === null) {
    //   this.actionObj.data = this.widgetDataPattern
    // }
  },
  methods: {
    ...TagDataGetElemsHelper,
    ...HistoryHelper,
    // getOptionsFormElems: function() {
    //   let data =[
    //     { value: null, text: 'Выберите действие' }
    //   ]
    //   for (let index in this.basePropElems.js.actions) {
    //     if (this.basePropElems.js.actions[index].parent.length && !this.basePropElems.js.actions[index].parent.includes(this.parent)) {
    //       continue
    //     }
    //
    //     if (this.basePropElems.js.actions[index].parent.includes(this.parent)) {
    //       data.unshift({
    //         text: this.basePropElems.js.actions[index].name,
    //         value: index
    //       })
    //       continue
    //     }
    //
    //     data.push({
    //       text: this.basePropElems.js.actions[index].name,
    //       value: index
    //     })
    //   }
    //   return data
    // },
  },
  mounted() {

  }
}
</script>

<style scoped>

</style>
